import React, {Component, ReactElement} from 'react';
import GridContainer from '../../../vendor/components/Grid/GridContainer';
import GridItem from '../../../vendor/components/Grid/GridItem';
import CustomFormInput from '../form_data/custom_form_input';
import CustomSelectFormInput from '../form_data/custom_select_form_input';
import {CustomForm, FormCategory, FormField, Option} from './custom_form_editor';
import MultiSelect from '../form_data/multi_select';
import {HelpModal} from '../Modal/Modal';
import DateTimeFormInput from '../form_data/date_time_picker_form_input';
import Danger from '../../../vendor/components/Typography/Danger';
import {
  current4HYear,
  formatMoney,
  validateDate,
  validateEmail,
  validateNumber,
  validatePhoneNumber
} from '../../shared/shared_helpers';
import CustomFileFormInput from '../form_data/custom_file_form_input';
import {FormData, FormDataContext} from '../form_data';
import {withSession} from '../../session_context';
import moment from 'moment';


type FormError = {
  fieldKey: string,
  fieldTitle: string,
  error: string
}

interface Props {
  session: any,
  customForm: CustomForm,
  enrollment: any,
  customFormData?: any,
  lockedFields?: string[],
  readOnly?: boolean,
  paperEnrollment?: boolean
  accountType?: any,
  errors?: FormError[],
  defaultSm?: number,
  account?: any,
  adminView?: any,
  memberType?: any,
  settings?: any,

  onChange?(newData: any): void,
}

const VALIDATORS = {
  numeric: validateNumber,
  date: validateDate,
  email: validateEmail,
  phoneNumber: validatePhoneNumber,
  limitMultiSelect(input: any, selectLimit: number) {
    if (!selectLimit) {
      return true;
    } else {
      return (input.length <= selectLimit);
    }
  },
  isConditionallyRequired(fieldValue: any, conditionalFieldValue: any, requiredValue: any) {
    // the following is in case the selected field is a multi-select in which case
    // the value will be an array rather than a string
    const value = typeof conditionalFieldValue === 'string' ? conditionalFieldValue.toLowerCase() : conditionalFieldValue,
        requiredFieldValue = (requiredValue || '')?.toLowerCase?.(),
        valuesMatch = Array.isArray(value)
            ? (value.map(v => v.toLowerCase()).indexOf(requiredFieldValue) > -1) : (value?.toLowerCase?.() === requiredFieldValue);
    return valuesMatch ? (fieldValue?.length > 0) : true;
  }
};

// @ts-ignore
export function validateGroupEnrollmentTotals(customForm: CustomForm, formData: Record<string, string>, numberOfParticipants, groupEnrollment, stateId): FormError[] {
  // console.log("CUSTOM FORM", customForm);
  //console.log("FORM DATA", formData);
  // console.log("NUMBER OF PARTICIPANTS", numberOfParticipants);
  // console.log("GROUP ENROLLMENT", groupEnrollment);
  //console.log("STATE ID", stateId);
  // @ts-ignore
  const errors: FormError[] = [];
  let gradeTotal = 0;
  let genderTotal = 0;
  let residenceTotal = 0;
  let ethnicityTotal = 0;
  let raceTotal = 0;

  if (numberOfParticipants < 0) {
    errors.push({
      fieldKey: 'participantNumInvalid',
      fieldTitle: 'Number of Participants Invalid',
      error: `"Total Number of Youth Participants (Non-Volunteer)" cannot be blank or less than or equal to 0`
    });
  }


  //=============================================================================
  //START OF EXAMPLE STATE GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'ex-st') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1stGrade']) || 0) + (parseInt(formData?.['2ndGrade']) || 0) +
        (parseInt(formData?.['3rdGrade']) || 0) + (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) + (parseInt(formData?.['8thGrade']) || 0) +
        (parseInt(formData?.['9thGrade']) || 0) + (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData.postHighSchool) || 0) + (parseInt(formData.notInSchool) || 0) +
        (parseInt(formData.specialEducation) || 0);

    genderTotal += (parseInt(formData.howManyTotalMaleYouthParticipants) || 0) + (parseInt(formData.howManyTotalFemaleYouthParticipants) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.townOfUnder10000AndRuralNonfarm) || 0) +
        (parseInt(formData?.townOrCity1000050000AndTheSuburbs) || 0) + (parseInt(formData?.suburbsOfCityOver50000) || 0) +
        (parseInt(formData?.centralCityOfOver50000) || 0);

    ethnicityTotal += (parseInt(formData?.hispanicOrLatino) || 0) + (parseInt(formData?.notHispanicOrLatino) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.blackOrAfricanAmerican) || 0) +
        (parseInt(formData?.americanIndianOrAlaskanNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.oneOrMoreRace) || 0) + (parseInt(formData?.someOtherRace) || 0) +
        (parseInt(formData?.nativeHawaiianOrOtherPacificIslands) || 0);
  }
  //END OF EXAMPLE STATE GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //===========================================================================


  //======================================================================
  //START OF ARKANSAS GROUP ENROLLMENT ES-237 DEMOGRAPHIC FIELD VALIDATION
  if (stateId === 'US-AR' || stateId === 'US-AR-DEMO') {
    gradeTotal += (parseInt(formData?.['kindergarten']) || 0) + (parseInt(formData?.['1stGrade']) || 0) +
        (parseInt(formData?.['2ndGrade']) || 0) + (parseInt(formData?.['3rdGrade']) || 0) +
        (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) +
        (parseInt(formData?.['8thGrade']) || 0) + (parseInt(formData?.['9thGrade']) || 0) +
        (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData?.postHighSchool) || 0) +
        (parseInt(formData?.notInSchool) || 0) + (parseInt(formData?.specialEducation) || 0)
        + (parseInt(formData?.gradeNotIdentified) || 0);

    genderTotal +=
        (parseInt(formData.numberOfMaleParticipantsNotIn4h) || 0) +
        (parseInt(formData.numberOfFemaleParticipantsNotIn4h) || 0) +
        (parseInt(formData.numberOfGenderNonidentifiedNotIn4h) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.townOfUnder10000AndRuralNonfarm) || 0) +
        (parseInt(formData?.townOrCity1000050000AndTheSuburbs) || 0) + (parseInt(formData?.suburbsOfCityOver50000) || 0) +
        (parseInt(formData?.centralCityOfOver50000) || 0) + (parseInt(formData?.notIdentified_1) || 0);

    ethnicityTotal += (parseInt(formData?.hispanicOrLatino) || 0) + (parseInt(formData?.notHispanicOrLatino) || 0)
        + (parseInt(formData?.undisclosed) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.blackOrAfricanAmerican) || 0) +
        (parseInt(formData?.asian) || 0) + (parseInt(formData?.americanIndianOrAlaskanNative) || 0) +
        (parseInt(formData?.nativeHawaiianOrOtherPacificIslands) || 0) + (parseInt(formData?.someOtherRace) || 0) +
        (parseInt(formData?.notIdentified) || 0);
  }


  //END OF ARKANSAS GROUP ENROLLMENT ES-237 DEMOGRAPHIC FIELD VALIDATION
  //====================================================================

  //=============================================================================
  //START OF ALASKA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-AK') {
    gradeTotal += (parseInt(formData?.['kindergarten']) || 0) + (parseInt(formData?.['firstGrade']) || 0) +
        (parseInt(formData?.['2ndGrade']) || 0) + (parseInt(formData?.['3rdGrade']) || 0) +
        (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) +
        (parseInt(formData?.['8thGrade']) || 0) + (parseInt(formData?.['9thGrade']) || 0) +
        (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData?.['unknownGrade']) || 0);


    // console.log("MALE PARTICIPANTS MATH", ((parseInt(formData.numberOfMaleParticipants) || 0) - (parseInt(formData.numberOfMale4hParticipants) || 0)));
    // console.log("FEMALE PARTICIPANTS MATH", ((parseInt(formData.numberOfFemaleParticipants) || 0) - (parseInt(formData.numberOfFemale4hParticipants) || 0)));
    // console.log("UNKNOWN PARTICIPANTS MATH", ((parseInt(formData.numberOfParticipantsOfUnknownGender) || 0) - (parseInt(formData.numberOf4hParticipantsOfUnknownGender) || 0)));
    // console.log("TOTAL GENDER PARTICIPANTS MATH",
    //     ((parseInt(formData.numberOfMaleParticipants) || 0) - (parseInt(formData.numberOfMale4hParticipants) || 0)) +
    //     ((parseInt(formData.numberOfFemaleParticipants) || 0) - (parseInt(formData.numberOfFemale4hParticipants) || 0)) +
    //     ((parseInt(formData.numberOfParticipantsOfUnknownGender) || 0) - (parseInt(formData.numberOf4hParticipantsOfUnknownGender) || 0))
    // );
    genderTotal +=
        ((parseInt(formData.numberOfMaleParticipants) || 0) - (parseInt(formData.numberOfMale4hParticipants) || 0)) +
        ((parseInt(formData.numberOfFemaleParticipants) || 0) - (parseInt(formData.numberOfFemale4hParticipants) || 0)) +
        ((parseInt(formData.numberOfParticipantsOfUnknownGender) || 0) - (parseInt(formData.numberOf4hParticipantsOfUnknownGender) || 0));

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.rural) || 0) +
        (parseInt(formData?.town1050k) || 0) + (parseInt(formData?.suburb) || 0) + (parseInt(formData?.city50k) || 0);

    ethnicityTotal += (parseInt(formData?.hispanic) || 0) + (parseInt(formData?.nonhispanic) || 0) +
        (parseInt(formData?.unknownEthnicity) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.black) || 0) +
        (parseInt(formData?.americanNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.hawaiianpacificIslander) || 0) + (parseInt(formData?.combination) || 0) +
        (parseInt(formData?.other) || 0) + (parseInt(formData?.unknown) || 0);
  }
  //END OF ARIZONA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //=====================================================================

  //=============================================================================
  //START OF ARIZONA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-AZ') {
    // gradeTotal +=
    //     (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1stGrade']) || 0) + (parseInt(formData?.['2ndGrade']) || 0) +
    //     (parseInt(formData?.['3rdGrade']) || 0) + (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
    //     (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) + (parseInt(formData?.['8thGrade']) || 0) +
    //     (parseInt(formData?.['9thGrade']) || 0) + (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
    //     (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData.postHighSchool) || 0) + (parseInt(formData.notInSchool) || 0) +
    //     (parseInt(formData.specialEducation) || 0);

    gradeTotal += numberOfParticipants;
    genderTotal += (parseInt(formData.numberOfYouthWhoIdentifyAsBoy) || 0) +
        (parseInt(formData.numberOfYouthWhoIdentifyAsTransgenderBoy) || 0) +
        (parseInt(formData.numberOfYouthWhoIdentifyAsGirls) || 0) +
        (parseInt(formData.numberOfYouthWhoIdentifyAsTransgenderGirl) || 0);

    residenceTotal += (parseInt(formData?.numberOfYouthWhoLiveOnFarmsIncomeEarned) || 0) +
        (parseInt(formData?.numberOfYouthWhoLiveInTownUnder10000RuralNonfarm) || 0) +
        (parseInt(formData?.numberOfYouthWhoLiveInTownsOrCities1000050000AndTheirSuburbs) || 0) +
        (parseInt(formData?.numberOfYouthWhoLiveInSuburbsOfCitiesOver50000) || 0) +
        (parseInt(formData?.numberOfYouthWhoLiveInCentralCitiesOfOver50000) || 0);

    ethnicityTotal += (parseInt(formData?.numberOfYouthWhoIdentifyAsHispanicOrLatinx) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsWhiteOrCaucasian) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAfricanamericanOrBlack) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAmericanIndianOrAlaskanNative) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAsianamericanOrAsian) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsMiddleEastern) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsMultiracial) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsPacificIslander) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAnIdentityNotListenSelfidentify) || 0);

    raceTotal += (parseInt(formData?.numberOfYouthWhoIdentifyAsWhiteOrCaucasian) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAfricanamericanOrBlack) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAmericanIndianOrAlaskanNative) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAsianamericanOrAsian) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsHispanicOrLatinx) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsMiddleEastern) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsMultiracial) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsPacificIslander) || 0) +
        (parseInt(formData?.numberOfYouthWhoIdentifyAsAnIdentityNotListenSelfidentify) || 0);
  }
  //END OF ARIZONA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //=====================================================================

  //===========================================================================
  //START OF CALIFORNIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-CA' || stateId === 'US-CA-DEMO') {
    gradeTotal +=
        (parseInt(formData?.k) || 0) + (parseInt(formData?.['1']) || 0) + (parseInt(formData?.['2']) || 0) +
        (parseInt(formData?.['3']) || 0) + (parseInt(formData?.['4']) || 0) + (parseInt(formData?.['5']) || 0) +
        (parseInt(formData?.['6']) || 0) + (parseInt(formData?.['7']) || 0) + (parseInt(formData?.['8']) || 0) +
        (parseInt(formData?.['9']) || 0) + (parseInt(formData?.['10']) || 0) + (parseInt(formData?.['11']) || 0) +
        (parseInt(formData?.['12']) || 0) + (parseInt(formData.postHighSchool) || 0) +
        (parseInt(formData.notInSchool) || 0) + (parseInt(formData.specialEducation) || 0) + (parseInt(formData.preferNotToState) || 0);

    genderTotal += (parseInt(formData.yManboy) || 0) + (parseInt(formData.yWomangirl) || 0)
        + (parseInt(formData.yNonbinary) || 0) + (parseInt(formData.yGenderIdentityNotListed) || 0)
        + (parseInt(formData.yPreferNotToState) || 0);

    residenceTotal += (parseInt(formData?.yFarm) || 0) + (parseInt(formData?.yRuralUnder10000) || 0) +
        (parseInt(formData?.yTown1000050000) || 0) + (parseInt(formData?.suburbOfCitiesLessThan50000) || 0)
        + (parseInt(formData?.yCentralCitiesGreaterThan50000) || 0) + (parseInt(formData.yPreferNotToStateResidence) || 0);

    ethnicityTotal += (parseInt(formData?.yHispanicLatino) || 0) + (parseInt(formData?.yNonhispanicLatino) || 0)
        + (parseInt(formData.yPreferNotToStateEthnicity) || 0);

    raceTotal += (parseInt(formData?.yAmerIndianAlaskaNative) || 0) + (parseInt(formData?.yAsian) || 0)
        + (parseInt(formData?.yBlackAfricanAmer) || 0) + (parseInt(formData?.yNativeHawaiianPacificIslander) || 0)
        + (parseInt(formData?.yRaceNotListed) || 0) + (parseInt(formData?.yWhite) || 0) + (parseInt(formData?.yMoreThan1) || 0)
        + (parseInt(formData?.prefernottostateyouthrace) || 0);
  }
  //END OF CALIFORNIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //=========================================================================


  //===========================================================================
  //START OF CONNECTICUT GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-CT') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1st']) || 0) + (parseInt(formData?.['2nd']) || 0) +
        (parseInt(formData?.['3rd']) || 0) + (parseInt(formData?.['4th']) || 0) + (parseInt(formData?.['5th']) || 0) +
        (parseInt(formData?.['6th']) || 0) + (parseInt(formData?.['7th']) || 0) + (parseInt(formData?.['8th']) || 0) +
        (parseInt(formData?.['9th']) || 0) + (parseInt(formData?.['10th']) || 0) + (parseInt(formData?.['11th']) || 0) +
        (parseInt(formData?.['12th']) || 0) + (parseInt(formData.postHighSchool) || 0) + (parseInt(formData.notInSchool) || 0) + (parseInt(formData.undeterminedGrade) || 0);

    genderTotal += (parseInt(formData.maleParticipants) || 0) + (parseInt(formData.femaleParticipants) || 0) +
        (parseInt(formData.otherParticipants) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.townLessThan10k) || 0) +
        (parseInt(formData?.town10k50k) || 0) + (parseInt(formData?.suburbs50k) || 0) + (parseInt(formData?.cities50k) || 0) + (parseInt(formData.undeterminedResidence) || 0);

    ethnicityTotal += (parseInt(formData?.hispanic) || 0) + (parseInt(formData?.nonhispanic) || 0) + (parseInt(formData.undeterminedEthnicity) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.black) || 0) + (parseInt(formData?.americanNative) || 0) +
        (parseInt(formData?.asian) || 0) + (parseInt(formData?.moreThan1Race) || 0) + (parseInt(formData?.undetermined) || 0) +
        (parseInt(formData?.hawaiianpacIsland) || 0);
  }
  //END OF CONNECTICUT GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //=========================================================================


  //======================================================================
  //START OF HAWAII GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-HI') {
    gradeTotal +=
        (parseInt(formData?.numberInK) || 0) + (parseInt(formData?.['numberInGrade1']) || 0) +
        (parseInt(formData?.['numberInGrade2']) || 0) + (parseInt(formData?.['numberInGrade3']) || 0) +
        (parseInt(formData?.['numberInGrade4']) || 0) + (parseInt(formData?.['numberInGrade5']) || 0) +
        (parseInt(formData?.['numberInGrade6']) || 0) + (parseInt(formData?.['numberInGrade7']) || 0) +
        (parseInt(formData?.['numberInGrade8']) || 0) + (parseInt(formData?.['numberInGrade9']) || 0) +
        (parseInt(formData?.['numberInGrade10']) || 0) + (parseInt(formData?.['numberInGrade11']) || 0) +
        (parseInt(formData?.['numberInGrade12']) || 0) + (parseInt(formData.numberInPostHs) || 0) +
        (parseInt(formData.numberNotInSchool) || 0) + (parseInt(formData.Unknown) || 0);

    genderTotal += (parseInt(formData.numberOfMales) || 0) + (parseInt(formData.numberOfFemales) || 0) + (parseInt(formData.GenderUnknown) || 0);

    residenceTotal += (parseInt(formData?.numberOnAFarm) || 0) + (parseInt(formData?.numberInRural10k) || 0) +
        (parseInt(formData?.numberInTown50k) || 0) + (parseInt(formData?.numberInSuburb) || 0) +
        (parseInt(formData?.numberInCity50k) || 0) + (parseInt(formData?.Undetermined) || 0);

    ethnicityTotal += (parseInt(formData?.numberOfHispanic) || 0) + (parseInt(formData?.numberOfNonhispanic) || 0) +
        (parseInt(formData?.EthnicityUnknown) || 0);

    raceTotal += (parseInt(formData?.numberOfWhite) || 0) + (parseInt(formData?.numberOfBlack) || 0) +
        (parseInt(formData?.numberNativeAmericanAlaskaNative) || 0) + (parseInt(formData?.numberOfAsian) || 0) +
        (parseInt(formData?.numberOfPacificIslander) || 0) + (parseInt(formData?.numberOfHawaiian) || 0) +
        (parseInt(formData?.numberOfMixedRace) || 0) + (parseInt(formData?.numberOfUndetermined) || 0);
  }
  //END OF HAWAII GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //====================================================================

  //======================================================================
  //START OF MAINE GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-ME') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1stGrade']) || 0) +
        (parseInt(formData?.['2ndGrade']) || 0) + (parseInt(formData?.['3rdGrade']) || 0) +
        (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) +
        (parseInt(formData?.['8thGrade']) || 0) + (parseInt(formData?.['9thGrade']) || 0) +
        (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData.posthighSchool) || 0) +
        (parseInt(formData.notInSchool) || 0);

    // console.log("FULL GENDER MATH",
    //     ((parseInt(formData.howManyMales) || 0) - (parseInt(formData.howManyMalesAre4hMembers) || 0))
    //     + ((parseInt(formData.howManyFemales) || 0) - (parseInt(formData.howManyFemalesAre4hMembers) || 0))
    //     + ((parseInt(formData.howManyGenderNotListed) || 0) - (parseInt(formData.howManyGenderNotListedAre4hMembers) || 0))
    //     + ((parseInt(formData.howManyPreferNotToSpecifyGender) || 0) - (parseInt(formData.howManyPreferNotToSpecifyGenderAre4hMembers) || 0))
    // );
    //console.log("MALE MATH", ((parseInt(formData.howManyMales) || 0) - (parseInt(formData.howManyMalesAre4hMembers) || 0)));
    //console.log("FEMALE MATH", ((parseInt(formData.howManyFemales) || 0) - (parseInt(formData.howManyFemalesAre4hMembers) || 0)));
    //console.log("GENDER NOT LISTED MATH", ((parseInt(formData.howManyGenderNotListed) || 0) - (parseInt(formData.howManyGenderNotListedAre4hMembers) || 0)));
    //console.log("PREFER NOT TO SPECIFY MATH", ((parseInt(formData.howManyPreferNotToSpecifyGender) || 0) - (parseInt(formData.howManyPreferNotToSpecifyGenderAre4hMembers) || 0)));
    genderTotal += (
        ((parseInt(formData.howManyMales) || 0) - (parseInt(formData.howManyMalesAre4hMembers) || 0))
        + ((parseInt(formData.howManyFemales) || 0) - (parseInt(formData.howManyFemalesAre4hMembers) || 0))
        + ((parseInt(formData.howManyGenderNotListed) || 0) - (parseInt(formData.howManyGenderNotListedAre4hMembers) || 0))
        + ((parseInt(formData.howManyPreferNotToSpecifyGender) || 0) - (parseInt(formData.howManyPreferNotToSpecifyGenderAre4hMembers) || 0))
    );

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.rural) || 0) +
        (parseInt(formData?.town10k50k) || 0) + (parseInt(formData?.suburb) || 0) +
        (parseInt(formData?.city50k) || 0);

    ethnicityTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.black) || 0) +
        (parseInt(formData?.americanNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.hawaiianpacificIslander) || 0) + (parseInt(formData?.whiteBlack) || 0) +
        (parseInt(formData?.whiteAmericanNative) || 0) + (parseInt(formData?.blackAmericanNative) || 0) +
        (parseInt(formData?.whiteAsian) || 0) + (parseInt(formData?.otherCombinations) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_white) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_black) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_americanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_asian) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_hawaiianpacificIslander) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteBlack) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteAmericanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_blackAmericanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteAsian) || 0) +
        (parseInt(formData?.unknownpreferNotToState) || 0) +
        (parseInt(formData?.unknownpreferNotToState_1) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_otherCombinations) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.black) || 0) +
        (parseInt(formData?.americanNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.hawaiianpacificIslander) || 0) + (parseInt(formData?.whiteBlack) || 0) +
        (parseInt(formData?.whiteAmericanNative) || 0) + (parseInt(formData?.blackAmericanNative) || 0) +
        (parseInt(formData?.whiteAsian) || 0) + (parseInt(formData?.otherCombinations) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_white) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_black) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_americanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_asian) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_hawaiianpacificIslander) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteBlack) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteAmericanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_blackAmericanNative) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_whiteAsian) || 0) +
        (parseInt(formData?.unknownpreferNotToState) || 0) +
        (parseInt(formData?.unknownpreferNotToState_1) || 0) +
        (parseInt(formData?.nonhispanicEthnicityDuplicated_otherCombinations) || 0);
  }
  //END OF MAINE GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //====================================================================

  //======================================================================
  //START OF MONTANA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-MT') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1stGrade']) || 0) +
        (parseInt(formData?.['2ndGrade']) || 0) + (parseInt(formData?.['3ndGrade']) || 0) +
        (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) +
        (parseInt(formData?.['8thGrade']) || 0) + (parseInt(formData?.['9thGrade']) || 0) +
        (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData.postHighSchool) || 0) +
        (parseInt(formData.notInSchool) || 0) + (parseInt(formData.special) || 0);

    genderTotal += (
            (parseInt(formData?.['Male Gender (Total) ']) || 0) //leave the space after the parenthesis as that's included in the fieldId so if it's removed it won't pull the data properly.
            - (parseInt(formData?.['Male (Duplicates)']) || 0)
        ) +
        (
            (parseInt(formData?.['Female Gender (Total Number)']) || 0)
            - (parseInt(formData?.['Female (Duplicates)']) || 0)
        );

    residenceTotal += (parseInt(formData?.Farm) || 0) + (parseInt(formData?.ruralUnder10000) || 0) +
        (parseInt(formData?.town1000050000) || 0) + (parseInt(formData?.suburbsOfCitiesLessThan50000) || 0) +
        (parseInt(formData?.centralCitiesGreaterThan50000) || 0);

    ethnicityTotal += (parseInt(formData?.hispanicOrLatino) || 0) + (parseInt(formData?.nonhispanic) || 0);

    raceTotal += (parseInt(formData?.White) || 0) +
        //(parseInt(formData?.racialDistribution_1_white) || 0) +
        (parseInt(formData?.blackOrAfricanAmerican) || 0) +
        //(parseInt(formData?.racialDistribution_1_blackOrAfricanAmerican) || 0) +
        (parseInt(formData?.americanIndianOrAlaskanNative) || 0) +
        //(parseInt(formData?.racialDistribution_1_americanIndianOrAlaskanNative) || 0) +
        (parseInt(formData?.asian) || 0) +
        //(parseInt(formData?.racialDistribution_1_asian) || 0) +
        (parseInt(formData?.nativeHawaiianOrOtherPacificIslander) || 0) +
        //(parseInt(formData?.racialDistribution_1_nativeHawaiianOrOtherPacificIslander) || 0) +
        (parseInt(formData?.multipleRaces) || 0);
    //(parseInt(formData?.racialDistribution_1_multipleRaces) || 0);
  }
  //END OF MONTANA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //====================================================================


  //==========================================================================
  //START OF NEW MEXICO GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-NM') {
    gradeTotal += (parseInt(formData?.PreKCount) || 0) +
        (parseInt(formData?.KindergartenCount) || 0) + (parseInt(formData?.GradesIncluded_kindergarten) || 0) +
        (parseInt(formData?.['Grade1Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade1']) || 0) +
        (parseInt(formData?.['grade2Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade2']) || 0) +
        (parseInt(formData?.['grade3Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade3']) || 0) +
        (parseInt(formData?.['grade4Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade4']) || 0) +
        (parseInt(formData?.['grade5Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade5']) || 0) +
        (parseInt(formData?.['grade6Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade6']) || 0) +
        (parseInt(formData?.['grade7Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade7']) || 0) +
        (parseInt(formData?.['grade8Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade8']) || 0) +
        (parseInt(formData?.['grade9Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade9']) || 0) +
        (parseInt(formData?.['grade10Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade10']) || 0) +
        (parseInt(formData?.['grade11Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade11']) || 0) +
        (parseInt(formData?.['grade12Count']) || 0) + (parseInt(formData?.['GradesIncluded_grade12']) || 0) +
        (parseInt(formData?.postHsCount) || 0) + (parseInt(formData?.GradesIncluded_postHs) || 0) +
        (parseInt(formData?.notInSchool) || 0) + (parseInt(formData?.GradesIncluded_notInSchool) || 0) +
        (parseInt(formData.specialEducationCount) || 0) + (parseInt(formData.GradesIncluded_specialEducation) || 0);

    genderTotal += (parseInt(formData.genderMale) || 0) + (parseInt(formData.genderTotalMaleCountDuplicated) || 0);

    residenceTotal += (parseInt(formData?.farmCount) || 0) + (parseInt(formData?.townUnder10000OrRuralNonfarm) || 0) +
        (parseInt(formData?.townCityOrSuburbs10000To50000Count) || 0) + (parseInt(formData?.cityCentralMoreThan50000) || 0);

    ethnicityTotal += (parseInt(formData?.HispanicCount) || 0) + (parseInt(formData?.NonHispanicCount) || 0);

    raceTotal += (parseInt(formData?.WhiteCount) || 0) + (parseInt(formData?.BlackCount) || 0) +
        (parseInt(formData?.AmericanIndianCount) || 0) + (parseInt(formData?.AsianCount) || 0) +
        (parseInt(formData?.NativeHawaiianCount) || 0) + (parseInt(formData?.PreferNotToStateCount) || 0) +
        (parseInt(formData?.balancemixedRaceCount) || 0);
  }
  //END OF NEW MEXICO GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //========================================================================

  //==========================================================================
  //START OF OHIO CSU GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-OH-CSU') {
    gradeTotal += (parseInt(formData?.['k']) || 0) + (parseInt(formData?.['1st']) || 0) +
        (parseInt(formData?.['2nd']) || 0) + (parseInt(formData?.['3rd']) || 0) +
        (parseInt(formData?.['4th']) || 0) + (parseInt(formData?.['5th']) || 0) +
        (parseInt(formData?.['6th']) || 0) + (parseInt(formData?.['7th']) || 0) +
        (parseInt(formData?.['8th']) || 0) + (parseInt(formData?.['9th']) || 0) +
        (parseInt(formData?.['10th']) || 0) + (parseInt(formData?.['11th']) || 0) +
        (parseInt(formData?.['12th']) || 0) + (parseInt(formData?.['postHighSchool']) || 0) +
        (parseInt(formData?.['notInSchool']) || 0) + (parseInt(formData?.['special']) || 0);

    // genderTotal += (parseInt(formData.howManyOfTheseMaleParticipantsAreNotEnrolled4hMembers) || 0)
    //     + (parseInt(formData.howManyOfTheseFemaleParticipantsAreNotEnrolled4hMembers) || 0);

    genderTotal += ((parseInt(formData.totalNumberOfMaleParticipants) || 0) - (parseInt(formData.howManyOfTheseMaleParticipantsAreEnrolled4hMembers) || 0)) +
        ((parseInt(formData.totalNumberOfFemaleParticipants) || 0) - (parseInt(formData.howManyOfTheseFemaleParticipantsAreEnrolled4hMembers) || 0));

    residenceTotal += (parseInt(formData?.Farm) || 0) + (parseInt(formData?.rural) || 0) +
        (parseInt(formData?.town1000050000) || 0) + (parseInt(formData?.suburbOfCitiesLessThan50000) || 0)
        + (parseInt(formData?.centralCitiesGreaterThan50000) || 0);

    ethnicityTotal += (parseInt(formData?.hispanicOrLatino) || 0) + (parseInt(formData?.notHispanicOrLatino) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.blackOrAfricanAmerican) || 0) +
        (parseInt(formData?.asian) || 0) + (parseInt(formData?.americanIndianOrAlaskanNative) || 0) +
        (parseInt(formData?.nativeHawaiianOrOtherPacificIslands) || 0) + (parseInt(formData?.oneOrMoreRace) || 0);
  }
  //END OF NEW MEXICO GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //========================================================================


  //============================================================================
  //START OF PENNSYLVANIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-PA') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.first) || 0) + (parseInt(formData?.second) || 0) +
        (parseInt(formData?.third) || 0) + (parseInt(formData?.fourth) || 0) + (parseInt(formData?.fifth) || 0) +
        (parseInt(formData?.sixth) || 0) + (parseInt(formData?.seventh) || 0) + (parseInt(formData?.eighth) || 0) +
        (parseInt(formData?.ninth) || 0) + (parseInt(formData?.tenth) || 0) + (parseInt(formData?.eleventh) || 0) +
        (parseInt(formData?.twelfth) || 0) + (parseInt(formData?.postHighSchool) || 0) + (parseInt(formData?.notInSchool) || 0) +
        (parseInt(formData?.special) || 0) + (parseInt(formData?.['Undetermined Grade']) || 0);

    genderTotal += (parseInt(formData.male) || 0) + (parseInt(formData.female) || 0) +
        (parseInt(formData.undetermined) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.ruralUnder10000) || 0) +
        (parseInt(formData?.town1000050000) || 0) + (parseInt(formData?.suburbOfCityLessThan50000) || 0) +
        (parseInt(formData?.centralCityGreaterThan50000) || 0) + (parseInt(formData?.['Undetermined Residence']) || 0);

    ethnicityTotal += (parseInt(formData?.hispanic) || 0) + (parseInt(formData?.nonhispanic) || 0) +
        (parseInt(formData?.['Undetermined Ethnicity']) || 0);

    raceTotal += (parseInt(formData?.whiteOnly) || 0) + (parseInt(formData?.africanAmericanOnly) || 0) +
        (parseInt(formData?.americanIndianOrAlaskanNativeOnly) || 0) + (parseInt(formData?.asianOnly) || 0) +
        (parseInt(formData?.otherCombinations) || 0) + (parseInt(formData?.nativeHawaiianotherPacificIslanderOnly) || 0) +
        (parseInt(formData?.['Undetermined Race']) || 0)
  }
  //END OF PENNSYLVANIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //==========================================================================


  //============================================================================
  //START OF RHODE ISLAND GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-RI') {
    gradeTotal +=
        (parseInt(formData?.k) || 0) + (parseInt(formData?.['1st']) || 0) + (parseInt(formData?.['2nd']) || 0) +
        (parseInt(formData?.['3rd']) || 0) + (parseInt(formData?.['4th']) || 0) + (parseInt(formData?.['5th']) || 0) +
        (parseInt(formData?.['6th']) || 0) + (parseInt(formData?.['7th']) || 0) + (parseInt(formData?.['8th']) || 0) +
        (parseInt(formData?.['9th']) || 0) + (parseInt(formData?.['10th']) || 0) + (parseInt(formData?.['11th']) || 0) +
        (parseInt(formData?.['12th']) || 0) + (parseInt(formData?.postHighSchool) || 0) +
        (parseInt(formData?.notInSchool) || 0);

    genderTotal += (parseInt(formData.males) || 0) + (parseInt(formData.females) || 0) +
        (parseInt(formData?.['Gender not Listed Above']) || 0) + (parseInt(formData?.['Prefer not to Respond']) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.ruralUnder10k) || 0) +
        (parseInt(formData?.town10k50k) || 0) + (parseInt(formData?.suburbLessThan50k) || 0) + (parseInt(formData?.suburbOver50k) || 0) +
        (parseInt(formData?.cityOver50k) || 0);

    ethnicityTotal += (parseInt(formData?.hispanic) || 0) + (parseInt(formData?.nonhispanic) || 0);

    raceTotal += (parseInt(formData?.race_white) || 0) + (parseInt(formData?.race_Black) || 0) +
        (parseInt(formData?.americanIndianalaskaNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.hawaiianPacificIslander) || 0) + (parseInt(formData?.moreThan1Race) || 0);
  }
  //END OF RHODE ISLAND GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //==========================================================================

  //=============================================================================
  //START OF WEST VIRGINIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  if (stateId === 'US-WV') {
    gradeTotal +=
        (parseInt(formData?.kindergarten) || 0) + (parseInt(formData?.['1stGrade']) || 0) + (parseInt(formData?.['2ndGrade']) || 0) +
        (parseInt(formData?.['3rdGrade']) || 0) + (parseInt(formData?.['4thGrade']) || 0) + (parseInt(formData?.['5thGrade']) || 0) +
        (parseInt(formData?.['6thGrade']) || 0) + (parseInt(formData?.['7thGrade']) || 0) + (parseInt(formData?.['8thGrade']) || 0) +
        (parseInt(formData?.['9thGrade']) || 0) + (parseInt(formData?.['10thGrade']) || 0) + (parseInt(formData?.['11thGrade']) || 0) +
        (parseInt(formData?.['12thGrade']) || 0) + (parseInt(formData?.postHighSchool) || 0) + (parseInt(formData?.notInSchool) || 0) +
        (parseInt(formData?.specialEducation) || 0) + (parseInt(formData?.undisclosedGrade) || 0);

    genderTotal += (parseInt(formData.howManyOfTheseMaleYouthParticipantsAreNotEnrolled4hMembers) || 0) +
        (parseInt(formData.howManyOfTheseFemaleYouthParticipantsAreNotEnrolled4hMembers) || 0) +
        (parseInt(formData.howManyOfTheseOtherYouthParticipantsAreNotEnrolledIn4h) || 0) +
        (parseInt(formData.howManyOfTheseUnknownYouthParticipantsAreNotEnrolledIn4h) || 0);

    residenceTotal += (parseInt(formData?.farm) || 0) + (parseInt(formData?.townOfUnder10000AndRuralNonfarm) || 0) +
        (parseInt(formData?.townOrCity1000050000AndTheSuburbs) || 0) + (parseInt(formData?.suburbsOfCityOver50000) || 0) +
        (parseInt(formData?.centralCityOfOver50000) || 0) + (parseInt(formData?.undisclosedResidence) || 0);

    ethnicityTotal += (parseInt(formData?.hispanicOrLatino) || 0) + (parseInt(formData?.notHispanicOrLatino) || 0)
        + (parseInt(formData?.undisclosed) || 0) + (parseInt(formData?.unknownEthnicity) || 0);

    raceTotal += (parseInt(formData?.white) || 0) + (parseInt(formData?.blackOrAfricanAmerican) || 0) +
        (parseInt(formData?.americanIndianOrAlaskanNative) || 0) + (parseInt(formData?.asian) || 0) +
        (parseInt(formData?.nativeHawaiianOrOtherPacificIslands) || 0) + (parseInt(formData?.oneOrMoreRace) || 0) +
        (parseInt(formData?.someOtherRace) || 0) +
        (parseInt(formData?.undisclosedRace) || 0) +
        (parseInt(formData?.unknownRace) || 0);
  }
  //END OF WEST VIRGINIA GROUP ENROLLMENTS ES-237 DEMOGRAPHIC FIELD VALIDATIONS
  //===========================================================================


  // console.log("TOTAL NUMBER OF YOUTH PARTICIPANTS", numberOfParticipants);
  // console.log("GRADE TOTAL", gradeTotal);
  // console.log("GENDER TOTAL", genderTotal);
  // console.log("RESIDENCE TOTAL", residenceTotal);
  // console.log("ETHNICITY TOTAL", ethnicityTotal);
  // console.log("RACE TOTAL", raceTotal);

  if (gradeTotal !== numberOfParticipants) {
    errors.push({
      fieldKey: 'gradeTotalInvalid',
      fieldTitle: 'Grade Total Invalid',
      error: `The Total Sum of the Grade Fields DOES NOT Equal "Total Number of Youth Participants"`
    });
  }

  if (genderTotal !== numberOfParticipants) {
    errors.push({
      fieldKey: 'genderTotalInvalid',
      fieldTitle: 'Gender Total Invalid',
      error: `The Total Sum of the Gender fields DOES NOT Equal "Total Number of Youth Participants"`

    });
  }

  if (residenceTotal !== numberOfParticipants) {
    errors.push({
      fieldKey: 'residenceTotalInvalid',
      fieldTitle: 'Residence Total Invalid',
      error: `The Total Sum of the Residence Fields DOES NOT equal "Total number of youth participants"`
    });
  }

  if (ethnicityTotal !== numberOfParticipants) {
    errors.push({
      fieldKey: 'ethnicityTotalInvalid',
      fieldTitle: 'Ethnicity Total Invalid',
      error: `The Total Sum of the Ethnicity Fields DOES NOT Equal "Total Number of Youth Participants"`
    });
  }

  if (raceTotal !== numberOfParticipants) {
    errors.push({
      fieldKey: 'raceTotalInvalid',
      fieldTitle: 'Race Total Invalid',
      error: `The Total sum of the Race Fields DOES NOT Equal "Total Number of Youth Participants"`
    });
  }
  return errors;
}

export function validateCustomFormWithFields(customForm: CustomForm, formData: Record<string, string>, numberOfParticipants: any, props: Props): FormError[] {

  const stateId = props?.session?.settings?.state?.stateId ? props?.session?.settings?.state?.stateId : null;
  // console.log("STATE ID", stateId);
  const errors: FormError[] = [],
      fieldById: Record<string, any> = {};

  if (numberOfParticipants < 0) {
    errors.push({
      fieldKey: 'participantNumInvalid',
      fieldTitle: 'Number of Participants Invalid',
      error: `"Total Number of Youth Participants (Non-Volunteer)" cannot be blank or less than 0"`
    });
  }
  if (formData.yearsIn4h === '0' || formData.yearsIn4H === '0') {
    errors.push({
      fieldKey: "yearsIn4hInvalid",
      fieldTitle: "Years in 4-H Invalid",
      error: "Years in 4-H must be more than 0. If this is your first year in 4-H then put 1."
    })
  }


  for (let category of (customForm || [])) {
    for (let input of (category?.children || [])) {
      fieldById[input.key] = input;
      const pageLink = window.location.href || '';


      //VALIDATION CODE IF THE PAGE LINK INCLUDES EXTERNAL IN IT (EXTERNAL EVENT REGISTRATION ONLY)
      if ((input.isRequired === undefined || input.isRequired) && !input.hideField && pageLink.includes('external')) {

        if (input.fieldVisibility === 'External Registrations' || input.fieldVisibility === undefined || input.fieldVisibility === 'Both') {
          if (!formData[input.key] || formData[input.key].length === 0) {
            errors.push({
              fieldKey: input.key,
              fieldTitle: input.title,
              error: `${input.title} is required`
            });
          }

          if (input.type === 'select') {
            const selectError = validateSubSelectIsSelected(input, formData);
            if (selectError) {
              errors.push(selectError);
            }
          }
        }
      }

      //VALIDATION CODE IF THE PAGE LINK DOES NOT INCLUDE EXTERNAL IN IT (INTERNAL EVENT REGISTRATION AND ENROLLMENTS)
      if ((input.isRequired === undefined || input.isRequired) && !input.hideField && !pageLink.includes('external') && input.whoCanEditField !== 'Admins Only') {
        if (input.fieldVisibility === 'Internal Registrations' || input.fieldVisibility === undefined || input.fieldVisibility === 'Both') {
          if (!formData[input.key] || formData[input.key].length === 0) {
            errors.push({
              fieldKey: input.key,
              fieldTitle: input.title,
              error: `${input.title} is required`
            });
          }

          if (input.type === 'select') {
            const selectError = validateSubSelectIsSelected(input, formData);
            if (selectError) {
              errors.push(selectError);
            }
          }
        }
      }

      if (input.isSignatureDate) {
        // console.log("INPUT", input);
        // console.log("FORM DATA", formData[input.key]);
        // console.log("FORM DATA", formData);
        console.log("PROPS", props);
        console.log("")
        const enrollmentYear = props?.enrollment?.enrollmentYear ? props?.enrollment?.enrollmentYear
            : props?.session?.settings?.state ? current4HYear(props?.session?.settings?.state)
                : props?.settings?.state ? current4HYear(props?.settings?.state)
                    : null;
        const yearStartMonth = props?.session?.settings?.state?.yearStartMonth || props?.settings?.state?.yearStartMonth;
        const yearStartDay = props?.session?.settings?.state?.yearStartDay || props?.settings?.state?.yearStartDay;
        // props?.enrollment?.enrollmentYear
        // || current4HYear(props?.session?.settings?.state)
        //     || current4HYear(props?.settings?.state);

        const startDate = enrollmentYear + '-' +
            (yearStartMonth + 1).toString().padStart(2, '0') + '-' +
            (yearStartDay).toString().padStart(2, '0');

        const endDate = (enrollmentYear + 1) + '-' +
            (yearStartMonth + 1).toString().padStart(2, '0') + '-' +
            (yearStartDay).toString().padStart(2, '0');


        // const startDate = enrollmentYear + '-' +
        //     (props?.session?.settings?.state?.yearStartMonth + 1 || moment().month()).toString().padStart(2, '0') + '-' +
        //     (props?.session?.settings?.state?.yearStartDay || moment().day()).toString().padStart(2, '0');
        // const endDate = (enrollmentYear + 1) + '-' +
        //     (props?.session?.settings?.state?.yearStartMonth + 1 || moment().month()).toString().padStart(2, '0') + '-' +
        //     (props?.session?.settings?.state?.yearStartDay || moment().day()).toString().padStart(2, '0');
        // console.log("START DATE", startDate);
        // console.log("END DATE", endDate);
        // console.log("DATE TEST", (!(moment(formData[input.key]).isSameOrAfter(startDate) && moment(formData[input.key]).isBefore(endDate))));

        if (!(moment(formData[input.key]).isSameOrAfter(startDate) && moment(formData[input.key]).isBefore(endDate))) {

          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} has a date that is outside of the current 4-H year (${moment(startDate).format("M/D/YY")} - ${moment(endDate).subtract(1, 'd').format("M/D/YY")})`
          });
        }

      }

      // if (input.isRequired === undefined || input.isRequired) {
      //     if (!formData[input.key] || formData[input.key].length === 0) {
      //         errors.push({fieldKey: input.key, fieldTitle: input.title, error: `${input.title} is required`});
      //     }
      //
      //     if (input.type === 'select') {
      //         const selectError = validateSubSelectIsSelected(input, formData);
      //         if (selectError) {
      //             errors.push(selectError);
      //         }
      //     }
      // }


      if (input.validator === 'numeric' && !VALIDATORS.numeric(formData[input.key])) {
        errors.push({
          fieldKey: input.key, fieldTitle: input.title, error: `${input.title} must be a number`
        });
      } else if (input.validator === 'date' && formData[input.key]) {
        if (!VALIDATORS.date(formData[input.key])) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid date format, use / to separate the month, day, and year. Example: 01/01/${new Date().getFullYear()}`
          });
        }
      } else if (input.validator === 'email' && formData[input.key]) {
        if (!VALIDATORS.email(formData[input.key])) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid e-mail format, email needs to be in the form local-part@domain. Example: jsmith@example.com`
          });
        }
      } else if (input.validator === 'phoneNumber' && formData[input.key]) {
        if (!VALIDATORS.phoneNumber(formData[input.key]?.trim?.())) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid phone number format, phone number needs to be in the form 
                    (###) ###-#### or ###-####. Example: (567) 555-2345 or 555-2345`
          });
        }
      } else if (input.validator === 'selectionLimit' && formData[input.key]) {
        if (!VALIDATORS.limitMultiSelect(formData[input.key], input.multiSelectLimit)) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} can only have ${input.multiSelectLimit} selected`
          });
        }
      }
      if (input.isConditionallyRequired &&
          input.isConditionallyRequired.requiredFieldId && input.isConditionallyRequired.requiredFieldValue) {
        if (!VALIDATORS.isConditionallyRequired(formData[input.key], formData[input.isConditionallyRequired.requiredFieldId],
            input.isConditionallyRequired.requiredFieldValue)) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} cannot be left blank`
          });
        }

      }
    }
  }


  //COMMENTING THIS CODE OUT BECAUSE WE ADDED IN NEW VALIDATION CODE ABOVE
  if (stateId === 'US-CA') {
    for (let category of customForm) {
      for (let validation of category.categoryValidations || []) {
        if (validation.type === 'FIELD_SUM') {
          const fieldSum = ((validation as any).fieldIds || []).reduce((sum: number, id: string) =>
                  sum + (parseInt(formData[id]) || 0), 0),
              targetSum = ((validation as any).targetFieldIds || []).reduce((sum: number, id: string) =>
                  sum + (parseInt(formData[id]) || 0), 0);
          if (fieldSum !== targetSum) {
            errors.push({
              fieldKey: category.key,
              fieldTitle: category.title,
              error: `The sum of ${
                  ((validation as any).fieldIds || []).map((id: string) =>
                      fieldById[id]?.title).join(', ')
              } Must equal ${
                  ((validation as any).targetFieldIds || []).map((id: string) =>
                      fieldById[id]?.title).join(', ')
              }`
            });
          }
        }
      }
    }
  }


  // Remove duplicate errors
  errors.forEach((error: FormError) => {
    let count = 0;
    for (let j = 0; j < errors.length; j++) {
      if (errors[j].error === error.error) {
        if (count++ > 0) {
          errors.splice(j, 1);
        }
      }
    }
  });

  return errors;
}

export function validateProfileDataFields(customForm: CustomForm, formData: Record<string, string>, numberOfParticipants: any, props: Props): FormError[] {

  const stateId = props?.session?.settings?.state?.stateId ? props?.session?.settings?.state?.stateId : null;
  // console.log("STATE ID", stateId);
  const errors: FormError[] = [],
      fieldById: Record<string, any> = {};

  if (formData.yearsIn4h === '0' || formData.yearsIn4H === '0') {
    errors.push({
      fieldKey: "yearsIn4hInvalid",
      fieldTitle: "Years in 4-H Invalid",
      error: "Years in 4-H must be more than 0. If this is your first year in 4-H then put 1."
    })
  }


  for (let category of (customForm || [])) {
    for (let input of (category?.children || [])) {
      fieldById[input.key] = input;
      const pageLink = window.location.href || '';


      //VALIDATION CODE IF THE PAGE LINK INCLUDES EXTERNAL IN IT (EXTERNAL EVENT REGISTRATION ONLY)
      if ((input.isRequired === undefined || input.isRequired) && !input.hideFromParent && pageLink.includes('external')) {

        if (input.fieldVisibility === 'External Registrations' || input.fieldVisibility === undefined || input.fieldVisibility === 'Both') {
          if (!formData[input.key] || formData[input.key].length === 0) {
            errors.push({
              fieldKey: input.key,
              fieldTitle: input.title,
              error: `${input.title} is required`
            });
          }

          if (input.type === 'select') {
            const selectError = validateSubSelectIsSelected(input, formData);
            if (selectError) {
              errors.push(selectError);
            }
          }
        }
      }

      //VALIDATION CODE IF THE PAGE LINK DOES NOT INCLUDE EXTERNAL IN IT (INTERNAL EVENT REGISTRATION AND ENROLLMENTS)
      if ((input.isRequired === undefined || input.isRequired) && !input.hideFromParent && !pageLink.includes('external') && input.whoCanEditField !== 'Admins Only') {
        if (input.fieldVisibility === 'Internal Registrations' || input.fieldVisibility === undefined || input.fieldVisibility === 'Both') {
          if (!formData[input.key] || formData[input.key].length === 0) {
            errors.push({
              fieldKey: input.key,
              fieldTitle: input.title,
              error: `${input.title} is required`
            });
          }

          if (input.type === 'select') {
            const selectError = validateSubSelectIsSelected(input, formData);
            if (selectError) {
              errors.push(selectError);
            }
          }
        }
      }

      if (input.isSignatureDate) {
        const enrollmentYear = props?.enrollment?.enrollmentYear || current4HYear(props?.session?.settings?.state) || current4HYear(props?.session?.state);
        const startDate = enrollmentYear + '-' +
            (props?.session?.settings?.state?.yearStartMonth + 1 || moment().month()).toString().padStart(2, '0') + '-' +
            (props?.session?.settings?.state?.yearStartDay || moment().day()).toString().padStart(2, '0');
        const endDate = (enrollmentYear + 1) + '-' +
            (props?.session?.settings?.state?.yearStartMonth + 1 || moment().month()).toString().padStart(2, '0') + '-' +
            (props?.session?.settings?.state?.yearStartDay || moment().day()).toString().padStart(2, '0');

        if (!(moment(formData[input.key]).isSameOrAfter(startDate) && moment(formData[input.key]).isBefore(endDate))) {

          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} has a date that is outside of the current 4-H year (${moment(startDate).format("M/D/YY")} - ${moment(endDate).subtract(1, 'd').format("M/D/YY")})`
          });
        }

      }

      if (input.validator === 'numeric' && !VALIDATORS.numeric(formData[input.key])) {
        errors.push({
          fieldKey: input.key, fieldTitle: input.title, error: `${input.title} must be a number`
        });
      } else if (input.validator === 'date' && formData[input.key]) {
        if (!VALIDATORS.date(formData[input.key])) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid date format, use / to separate the month, day, and year. Example: 01/01/${new Date().getFullYear()}`
          });
        }
      } else if (input.validator === 'email' && formData[input.key]) {
        if (!VALIDATORS.email(formData[input.key])) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid e-mail format, email needs to be in the form local-part@domain. Example: jsmith@example.com`
          });
        }
      } else if (input.validator === 'phoneNumber' && formData[input.key]) {
        if (!VALIDATORS.phoneNumber(formData[input.key]?.trim?.())) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} isn't in valid phone number format, phone number needs to be in the form 
                    (###) ###-#### or ###-####. Example: (567) 555-2345 or 555-2345`
          });
        }
      } else if (input.validator === 'selectionLimit' && formData[input.key]) {
        if (!VALIDATORS.limitMultiSelect(formData[input.key], input.multiSelectLimit)) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} can only have ${input.multiSelectLimit} selected`
          });
        }
      }
      if (input.isConditionallyRequired &&
          input.isConditionallyRequired.requiredFieldId && input.isConditionallyRequired.requiredFieldValue) {
        if (!VALIDATORS.isConditionallyRequired(formData[input.key], formData[input.isConditionallyRequired.requiredFieldId],
            input.isConditionallyRequired.requiredFieldValue)) {
          errors.push({
            fieldKey: input.key,
            fieldTitle: input.title,
            error: `${input.title} cannot be left blank`
          });
        }

      }
    }
  }

  // Remove duplicate errors
  errors.forEach((error: FormError) => {
    let count = 0;
    for (let j = 0; j < errors.length; j++) {
      if (errors[j].error === error.error) {
        if (count++ > 0) {
          errors.splice(j, 1);
        }
      }
    }
  });

  return errors;
}


export function validateCustomFormData(customForm: CustomForm, formData: Record<string, string>, numberOfParticipants: any, props: Props): string[] {
  return validateCustomFormWithFields(customForm, formData, numberOfParticipants, props).map(r => r.error);
}

function validateSubSelectIsSelected(field: FormField, formData: Record<string, string>): FormError | undefined {
  const selectedValue = formData[field.key];
  if (!selectedValue) {
    return {
      fieldKey: field.key,
      fieldTitle: field.title,
      error: `${field.title} is required`
    };
  }
  const selectedChild = (field.children || []).find(c =>
      c.type === 'sub-select' && c.title === selectedValue && c.children?.length);
  if (selectedChild) {
    return validateSubSelectIsSelected({
      ...selectedChild,
      title: selectedChild.subFieldLabel || selectedChild.title,
      key: selectedChild.subFieldKey || selectedChild.key
    } as FormField, formData);
  }
}

export function autofillForm(source: Record<string, any>, customForm: CustomForm): Record<string, any> {
  const data = {} as Record<string, any>;
  for (let category of customForm) {
    for (let input of (category?.children || [])) {
      if (input.autofill) {
        data[input.key] = source[input.key];
      }
    }
  }
  return data;
}

type State = {
  externalOptions: Record<string, FormField[]>,
  errors?: Record<string, string>
};

/*function getInputValidator(field: FormField) {
    const defaultValidator = (s: string) => field.isRequired ? !!s.length : true,
        validatorType = field.validator || '';
    if (!validatorType) {
        return defaultValidator;
    }
    return (s: string) => defaultValidator(s) && ((VALIDATORS as any)[validatorType] || defaultValidator)(s);
}*/

function findOptionSources(form: CustomForm) {
  const results: { optionSource: string, key: string }[] = [];
  for (const category of form) {
    for (const field of (category.children || [])) {
      if ((['select', 'multi-select'].indexOf(field.type) > -1) && field.optionSource) {
        results.push({optionSource: field.optionSource, key: field.key});
      }
    }
  }
  return results;
}

class RenderCustomForm extends Component<Props, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {externalOptions: {}};
  }

  componentDidMount() {
    this.loadExternalOptions();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    this.loadExternalOptions();
  }

  loadExternalOptions() {
    const {backendClient} = this.props?.session;
    const externalOptions = {...this.state.externalOptions};
    let updateRequired = false;
    for (const source of findOptionSources(this.props.customForm)) {
      if (!externalOptions[source.key]) {
        externalOptions[source.key] = [];
        backendClient.getCustomFormOption({id: source.optionSource})
            .then((options: FormField[]) => {
              this.setState(s =>
                  ({externalOptions: {...s.externalOptions, [source.key]: options}}));
            });
        updateRequired = true;
      }
    }
    if (updateRequired) {
      this.setState({externalOptions});
    }
  }

  renderInput(input: FormField): ReactElement {
    const stateId = this.props?.session?.settings?.state?.stateId || '';
    //
    // console.log("PROPS", this.props);
    // console.log("ENROLLMENT", this.props.enrollment);
    // console.log("STATE", this.state);

    const isEnrollmentYearCurrent = this.props.enrollment ? this.props.enrollment.year === current4HYear(this.props?.session?.settings?.state) : true;
    // console.log("IS ENROLLMENT YEAR CURRENT", isEnrollmentYearCurrent);
    // console.log("this.props.enrollment", this.props.enrollment);
    // console.log("this.props.enrollment.year === current4HYear(this.props?.session?.settings?.state)", this?.props?.enrollment?.year === current4HYear(this.props?.session?.settings?.state));
    // console.log("this.props.enrollment.year", this?.props?.enrollment?.year);
    // console.log("current4HYear(this.props?.session?.settings?.state)", current4HYear(this.props?.session?.settings?.state));

    return <FormDataContext.Consumer>{({data: formData = {}}: { data: any }) => {

      //console.log("THIS.PROPS", this.props);

      const pageLink = window.location.href || '';

      // console.log("RENDER CUSTOM FORM FIELD INTERNAL REGISTRATIONS VISIBILITY TEST", (input?.fieldVisibility === 'Internal Registrations' && pageLink.includes('external')));
      //
      // console.log("RENDER CUSTOM FORM FIELD EXTERNAL REGISTRATIONS VISIBILITY TEST", (input?.fieldVisibility === 'External Registrations' && !pageLink.includes('external')));


      if (input.isConditionallyVisible?.visibleFieldId
          && input.isConditionallyVisible?.visibleFieldValue) {
        // the following is in case the selected field is a multi-select in which case
        // the value will be an array rather than a string
        const value = formData[input.isConditionallyVisible.visibleFieldId],
            visibleFieldValue = (input.isConditionallyVisible.visibleFieldValue || '')?.toLowerCase?.(),
            valuesMatch = Array.isArray(value)
                ? (value.map(v => v.toLowerCase()).indexOf(visibleFieldValue) > -1) : (value?.toLowerCase?.() === visibleFieldValue);
        if (!valuesMatch) {
          return null as any;
        }
      }

      //////////////
      // Hide Field
      //////////////

      if (input.hideField && (
          this.props?.account?.accountType !== 'StateAdmin' &&
          this.props?.account?.accountType !== 'CountyAdmin' &&
          this.props?.account?.accountType !== 'DistrictAdmin' &&
          this.props?.adminView !== true
      )) {
        return null as any;
      }

      if (
          this.props?.session?.settings?.showParentQuestions
          && input.hideFromParent
          && (this.props?.memberType === 'Parent')
      ) {
        // console.log("SHOW PARENT QUESTIONS HIT")
        // console.log("this.props?.account?.accountType", this.props?.account?.accountType)
        // console.log("this.props", this.props)
        return null as any;
      }

      if (
          (input?.fieldVisibility === 'Internal Registrations' && pageLink.includes('external')) && (
              this.props?.account?.accountType !== 'StateAdmin' &&
              this.props?.account?.accountType !== 'CountyAdmin' &&
              this.props?.account?.accountType !== 'DistrictAdmin' &&
              this.props?.adminView !== true
          )
      ) {
        return null as any;
      }

      if (
          (input?.fieldVisibility === 'External Registrations' && !pageLink.includes('external')) && (
              this.props?.account?.accountType !== 'StateAdmin' &&
              this.props?.account?.accountType !== 'CountyAdmin' &&
              this.props?.account?.accountType !== 'DistrictAdmin' &&
              this.props?.adminView !== true
          )
      ) {
        return null as any;
      }

      // console.log("INPUT", input);
      // console.log("INPUT TITLE", input.title);
      const fullTitle = input.title || '',
          shortTitle = fullTitle.length <= 40 ? input.title : '',
          errors = (this.props.errors || []).filter(e => e.fieldKey === input.key),
          errorComp = errors.length ?
              <div style={{marginTop: ['select', 'multi-select'].indexOf(input.type) > -1 ? -20 : 0}}>
                <Danger>{errors.map(e => e.error).join(', ')}</Danger>
              </div> : '';
      let comp = <span/>;

      //console.log("RENDER CUSTOM FORM PROPS", this.props);
      // console.log("INPUT KEY", input?.key);
      // console.log("INPUT WHO CAN EDIT FIELD", input?.whoCanEditField);
      //console.log("INPUT WHO CAN EDIT FIELD", (input?.whoCanEditField === 'Admins Only' && this.props?.accountType === 'Household'));
      // console.log("INPUT WHO CAN EDIT FIELD", (input?.whoCanEditField === 'Admins Only'));
      // console.log("ACCOUNT TYPE", (this.props?.accountType === 'Household'));
      //console.log("INPUT WHO CAN EDIT FIELD",this.props.readOnly && (stateId !== 'US-CA' && stateId !== 'US-CA-DEMO'));


      let caGrade = false;
      if (input?.key === 'grade' && formData.grade == null) {
        caGrade = false;
      } else if (input?.key === 'grade' && formData !== null) {
        if (this.props?.enrollment?.paperenrollment !== true && this.props.accountType === 'CountyAdmin') {
          caGrade = true
        }
      } else {
        caGrade = ((
            this.props.readOnly &&
            ((this.props?.enrollment?.paperenrollment === false && this.props.accountType === 'CountyAdmin')
                || this.props?.account?.accountType === 'Household' &&
                (
                    input?.key !== 'county' &&
                    input?.key !== 'allergies' &&
                    input?.key !== 'takingMedication' &&
                    input?.key !== 'healthConditions' &&
                    input?.key !== 'additionalAssistanceNeeded' &&
                    input?.key !== 'emobehavDifficulties' &&
                    input?.key !== 'lifefamilyEvents' &&
                    input?.key !== 'negMoodResponses' &&
                    input?.key !== 'emergencyInstructions' &&
                    input?.key !== 'tetanus'

                )
            )
            && (stateId === 'US-CA' || stateId === 'US-CA-DEMO')
        ) || false)
      }

      switch (input.type) {
        case 'text':
          comp = <CustomFormInput
              disabled={
                  (
                      this.props.readOnly && (stateId !== 'US-CA' && stateId !== 'US-CA-DEMO')
                  ) ||
                  (this.props.lockedFields || []).includes(input.key) ||
                  (this.props.adminView === true && this.props.accountType === 'Household') ||
                  (
                      input?.whoCanEditField === 'Admins Only' && this.props?.accountType === 'Household'
                  ) ||
                  (
                      (
                          this.props?.account?.accountType === 'Household' &&
                          input?.key === 'yearsIn4h' &&
                          stateId === 'US-WV' &&
                          this.props?.adminView === undefined
                      )

                      || (
                          this.props.readOnly &&
                          (
                              input?.key !== 'yearsIn4h' &&
                              (
                                  (this.props.paperEnrollment === false && this.props.accountType === 'CountyAdmin')
                                  || (this.props?.account?.accountType === 'Household' && this.props.adminView !== true) &&
                                  (
                                      input?.key !== 'memberLegalFirstName' &&
                                      input?.key !== 'memberLegalLastName' &&
                                      input?.key !== 'allergyDetails' &&
                                      input?.key !== 'otherMedications' &&
                                      input?.key !== 'currentMedications' &&
                                      input?.key !== 'conditionsDetails' &&
                                      input?.key !== 'additionalAssistanceDetails' &&
                                      input?.key !== 'emobehavDifficultiesDetails' &&
                                      input?.key !== 'lifefamilyEventDetails' &&
                                      input?.key !== 'negMoodResponseDetails' &&
                                      input?.key !== 'emergencyInstructionDetails' &&
                                      input?.key !== 'emergcontactfirstname' &&
                                      input?.key !== 'emergcontactlastname' &&
                                      input?.key !== 'emergcontactrelationship' &&
                                      input?.key !== 'emergcontactphone' &&
                                      input?.key !== 'tetanusdate' &&
                                      input?.key !== 'recievedimmunizations'


                                  )
                              )

                          )/* && this.props?.account?.accountType === 'CountyAdmin'*/
                          && (stateId === 'US-CA' || stateId === 'US-CA-DEMO')
                      )
                      || (
                          !this?.props?.session?.settings?.allowPreviousYearEditing && !isEnrollmentYearCurrent &&
                          this.props.accountType !== 'StateAdmin'
                      )
                  )
              }
              helperText={input?.whoCanEditField === 'Admins Only' ? 'Only an admin can edit this field.' : ''}
              id={input.key}
              required={input.isRequired === undefined || input.isRequired}
              labelText={shortTitle}
              error={!!errors.length}
              endAdornment={input.helpText && <HelpModal>{input.helpText}</HelpModal>}/>;
          break;
        case 'sub-select':
        case 'select':
          const externalOptions = input.optionSource
              ? this.state.externalOptions[input.key] : undefined;
          comp = <FormDataContext.Consumer>{({data}: any) => {
            const subSelect = data[input.key]
                ? (externalOptions || input.children).filter(c => c.type === 'sub-select'
                    && c.title === data[input.key]
                    && c.children?.length)
                    .map((c) => ({
                      ...c, title: c.subFieldLabel || c.title,
                      key: c.subFieldKey || c.key,
                      isRequired: input.isRequired,
                    }) as FormField)[0]
                : undefined;
            return <div>
              <CustomSelectFormInput
                  readOnly={
                      (this.props.readOnly && (stateId !== 'US-CA' && stateId !== 'US-CA-DEMO')) ||
                      (this.props.lockedFields || []).includes(input.key) ||
                      (this.props.adminView === true && this.props.accountType === 'Household') ||
                      ((stateId === 'US-CA' || stateId === 'US-CA-DEMO') && caGrade)
                    //   (
                    //       this.props.readOnly &&
                    //       ((this.props.paperEnrollment === false && this.props.accountType === 'CountyAdmin')
                    //           || this.props?.account?.accountType === 'Household' &&
                    //           (
                    //               input?.key !== 'county' &&
                    //               input?.key !== 'allergies' &&
                    //               input?.key !== 'takingMedication' &&
                    //               input?.key !== 'healthConditions' &&
                    //               input?.key !== 'additionalAssistanceNeeded' &&
                    //               input?.key !== 'emobehavDifficulties' &&
                    //               input?.key !== 'lifefamilyEvents' &&
                    //               input?.key !== 'negMoodResponses' &&
                    //               input?.key !== 'emergencyInstructions'
                    //           )
                    //       )
                    //       && (stateId === 'US-CA' || stateId === 'US-CA-DEMO')
                    // )
                  }
                  id={input.key} fullWidth
                  labelText={shortTitle}
                  error={!!errors.length}
                  required={input.isRequired === undefined || input.isRequired}
                  options={((externalOptions || input.children || []) as Option[]).map(c => ({
                    id: c.title, disabled: c.disabled,
                    name: c.optionLabel || (c.title + ((c.type === 'paid-option' && c.paidOptionAmount) ? ' (' + formatMoney(c.paidOptionAmount) + ')' : ''))
                  }))}
                  endAdornment={input.helpText && <HelpModal>{input.helpText}</HelpModal>}/>
              {subSelect ? this.renderInput(subSelect) : ''}
            </div>;
          }}
          </FormDataContext.Consumer>;
          break;
        case 'multi-select':
          comp = <MultiSelect id={input.key} labelText={shortTitle}
                              readOnly={
                                  (this.props.readOnly && (stateId !== 'US-CA' && stateId !== 'US-CA-DEMO')) ||
                                  (this.props.lockedFields || []).includes(input.key) ||
                                  (this.props.adminView === true && this.props.accountType === 'Household') ||
                                  (
                                      this.props.readOnly &&
                                      ((this.props.paperEnrollment === false && this.props.accountType === 'CountyAdmin')
                                          || this.props?.account?.accountType === 'Household' &&
                                          (input?.key !== 'authorizedMedications'))
                                      && (stateId === 'US-CA' || stateId === 'US-CA-DEMO')
                                  )
                              }
                              error={!!errors.length} fullWidth
                              endAdornment={input.helpText && <HelpModal>{input.helpText}</HelpModal>}
                              required={input.isRequired === undefined || input.isRequired}
                              options={((input.children || []) as Option[]).map(c => ({
                                id: c.title, disabled: c.disabled,
                                name: c.optionLabel || (c.title + (c.paidOptionAmount ? ' - ' + formatMoney(c.paidOptionAmount) : ''))
                              }))} noSort/>;
          break;
        case 'date':
          comp = <DateTimeFormInput
              dateFormat
              id={input.key}
              labelText={shortTitle}
              disabled={
                  (this.props.readOnly && (stateId !== 'US-CA' && stateId !== 'US-CA-DEMO'))
                  || (this.props.lockedFields || []).includes(input.key)
                  || (this.props.adminView === true && this.props.accountType === 'Household')
                  || (
                      this.props.readOnly &&
                      ((this.props.paperEnrollment === false && this.props.accountType === 'CountyAdmin')
                          || this.props?.account?.accountType === 'Household' &&
                          (
                              input?.key !== 'memberDateOfBirth' &&
                              input?.key !== 'tetanusdate'
                          ))
                      && (stateId === 'US-CA' || stateId === 'US-CA-DEMO')
                  )
              }
              error={!!errors.length}
              closeOnSelect
              endAdornment={input.helpText && <HelpModal>{input.helpText}</HelpModal>}
              required={input.isRequired === undefined || input.isRequired}
              options={(input.children || []).map(c => c.title)} noSort/>;
          break;
        case 'file':
          comp = <CustomFileFormInput
              id={input.key}
              labelText={shortTitle}
              disabled={this.props.readOnly || (this.props.lockedFields || []).includes(input.key)}
              error={!!errors.length}
              required={input.isRequired === undefined || input.isRequired}
              helperText={input.helpText}
              accept={input.accept}
          />;
      }

      return !shortTitle?.length
          ? <div>
            <label htmlFor={input.key}>
              {fullTitle}</label>
            {comp}
            {errorComp}
          </div> : <div style={{marginTop: 21}}>
            {comp}
            {errorComp}
          </div>;
    }}</FormDataContext.Consumer>;
  }

  renderForm() {
    return this.props.customForm.map((category: FormCategory, index) => (category.title ? [
      <GridItem xs={12} sm={12} key={category.title || index}>
        <h4 style={{marginTop: '3rem', marginBottom: '1rem'}}>{category.title}</h4>
      </GridItem>,
    ] : []).concat((category.children || []).map((input) =>
        <GridItem xs={12} sm={this.props.defaultSm || 6} key={input.key}>
          {this.renderInput(input)}
        </GridItem>))).reduce((a, b) => a.concat(b), []);
  }

  render() {
    const {customFormData, onChange} = this.props;

    if (onChange && customFormData) {
      return (
          <FormData data={customFormData}
                    onChange={(newData: any) => onChange({
                      ...customFormData, ...newData
                    })}>
            <GridContainer>
              {this.renderForm()}
            </GridContainer>
          </FormData>
      );
    } else {
      return <GridContainer>
        {this.renderForm()}
      </GridContainer>;
    }
  }
}

export default withSession(RenderCustomForm);
